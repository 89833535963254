@import '../App.css';

.app{
    background-color: var(--blu-chiaro);
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.home{
    padding: 20px;
    height: 100vh;
    background-color: var(--blu-chiaro);
    overflow: hidden;
}
.notifica{
  display: flex;
  justify-content: flex-end;
}

.notifica img{
  width: 25px;
  height: auto;
  margin-right: 10px;

}

.home-header{
  display: flex;
  justify-content: center;
}

.logo-home{
  width: 70px;
  height: 70px;
}
.nome{
    font-size: var(--dimensione-testo-grande);
    font-weight: bold;
}

.titolo{
    font-size: var(--dimensione-testo-grande);
    font-weight: bold;
    color: var(--arancione-scritte);

}
#carouselExampleIndicators{
    height: 190px; /* Imposta l'altezza massima a 200 pixel */
    background-color: var(--blu-chiaro);
    border-radius: 20px;
  }

.carousel-inner{
    height: 240px; /* Imposta l'altezza massima a 200 pixel */
    padding: 0;
    font-size: var(--dimensione-testo-piccolo);
}

.carousel-indicators{
  margin-bottom: 5px !important;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--arancione-scritte);
  border-radius: 20px;
  width: 5px !important;
  height: 5px !important;
  background-color: var(--arancione-scritte) !important;
  border-radius: 50% !important;
  margin-right: 15px !important;
}
 
.carousel-item{
    height: 90px; /* Imposta l'altezza massima a 200 pixel */
    padding: 0;
    font-size: var(--dimensione-testo-piccolo);
}


.consigliati{
  overflow: scroll;
  height: 430px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
}


.barra{
  z-index: 1000;
  position: absolute;
  width: 100vw;
  bottom: 0;
}

.bott button{
  padding-left: 20px;
  padding-right: 20px;
}