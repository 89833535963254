/* FormDataEvento.css */
.leaflet-div-icon {
  border: none !important;
  background: transparent;
}

/* Aggiungi stili personalizzati al calendario */
.custom-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

/* Rendi tutte le date non selezionabili */
.react-datepicker__day--selectable {
  cursor: not-allowed;
  pointer-events: none;
}

/* Stili aggiuntivi per evidenziare le date non selezionabili */
.react-datepicker__day--outside-month {
  color: #aaa;
}

.react-datepicker__day--selected {
  background-color: var(--blu) !important;
  color: #000 !important;
}

.react-datepicker__input-container input{
  max-width: 200px;
  padding-left: 45px;
}
/*----------------*/

.container-data {
  background-color: var(--blu-chiaro);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.container-data form{
  margin-top: 20px;
}

.info-quando {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--lavender-web);
  padding: 10px;
  min-width: 300px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 20px;
  padding-bottom: 20px;
  gap: 10px;
}

.post-attività {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

input {
  border-radius: 20px;
  border: 0;
  padding: 5px;
  max-width: 250px;
}

.slot-orari {
  overflow: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  max-height: 300px;
  min-width: 200px;
  overflow: auto;
  padding: 10px;
  border-radius: 20px;
  max-width: 250px;
  margin-top: 5px;
  border: 1px solid lightgray;
}

.slot-orari-error{
  overflow: hidden !important;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  max-height: 300px;
  min-width: 200px;
  overflow: auto;
  padding: 10px;
  border-radius: 20px;
  max-width: 250px;
  margin-top: 5px;
  border: 1px solid red;
}

.slot-item {
  padding: 2px;
  border-radius: 20px;
  width: calc(50% - 5px);
  /* Calcola la larghezza per due elementi in una riga con uno spazio di 5px tra di essi */
  box-sizing: border-box;
}

.slot-item p {
  margin: 0;
  padding: 0;
}

.input-error {
  border: 1px solid red;
}

.input-no-error {
  border: 1px solid lightgray;
}

.textarea{
  border-radius: 20px;
  border: 1px solid lightgray;
  padding: 5px;
}

.textarea-error{
  border-radius: 20px;
  border: 1px solid red;
  padding: 5px;
}