.container-form-mappa {
    background-color: var(--blu-chiaro);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}

.contenitore{
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
    margin-bottom: 50px;
}

.leaflet-container{
    z-index: 0 !important;
}

.titolo-sezione-mappa {
    color: var(--arancione-scritte);
    font-weight: bold;
    text-transform: uppercase;
    font-size: var(--dimensione-testo);
}


.leaflet-control-zoom {
    height: 50px;
    width: 30px;
    display: flex;
    flex-direction: column;
    border: none !important;
}

.leaflet-control-zoom a {
    height: 25px !important;
    width: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blu) !important;
    color: var(--bianco) !important;
    font-weight: bold;
    display: flex;
}

.leaflet-control-zoom-in {
    display: flex !important;
}

.leaflet-control-zoom-out {
    display: flex !important;
}

a {
    border-radius: 20px !important;
}


.sezione {
    text-transform: uppercase;
    font-weight: bold;
    color: var(--arancione-chiaro);
    margin-bottom: 10px;
}

.form-mappa {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    align-items: center;
    gap: 20px;
   
}

.dropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: var(--lavender-web);
    padding: 10px;
    min-width: 300px;
}

.el {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.contenuto-sezione {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
    padding: 10px;
}

.sezione-mappa {
    display: flex;
    align-items: center;
    flex-direction: column;

}

.info-palestra {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 20px;
    background-color: var(--lavender-web);
    padding: 10px;
    min-width: 300px;
    min-height: 130px;
}

.cointainer-form-mappa .bottone {
    max-width: 100px;
    margin-top: 30px;
}

.barra {
    z-index: 1000;
    position: absolute;
    width: 100vw;
    bottom: 0;
}

/* Aggiungi questi stili al tuo file CSS per colorare il bordo in rosso */
.is-invalid .react-select__control {
    border-color: #dc3545;
  }
  
  .is-invalid .react-select__value-container {
    color: #dc3545;
  }
  
  .is-invalid .react-select__single-value {
    color: #dc3545;
  }


  .css-t3ipsp-control{
    border-color: var(--blu) !important;
    box-shadow: 0 0 0 1px var(--blu) !important;
    z-index: 1000 !important;
  }


  .react-select__control{
    width: 250px !important;
    border-radius: 20px !important;
   
  }

  .errore-palestra{
    color: #dc3545;
    margin-top: 10px;
  }
  
  .contenitore-mappa{
    padding: 15px;
    border-radius: 20px;
    background-color: var(--lavender-web);
    margin-top: 10px;
  }


  .container-form-mappa .bottone {
    max-width: 100px;
    margin-top: 0;
  }

  
  .container-form-mappa .bottone-disattivo {
    max-width: 100px;
    margin-top: 0;
  }