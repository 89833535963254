.barra{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    
}
.barra img{
    height: 35px;
    width: 35px;
}

.icon {
    stroke: gray;
}

.icon.active {
    stroke: var(--arancione-chiaro)
}

.icon-fill {
    fill: gray;
}

.icon-fill.active {
    fill: var(--arancione-chiaro)
}