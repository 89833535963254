@import '../App.css';

.login{
    background-color: var(--blu-chiaro);
    padding: 20px;
    height: 100vh;
    background-image: url('/public/icons/sfondo-pagina-login.svg'); 
    background-size: 200% auto; 
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 350px;
    background-position-x: -190px;
}
.login-header {
    display: flex;
    justify-content: center;
}
.logo{
    width: 100px;
    height: auto;
}

.login-title{
    display: flex;
    justify-content: center;
    margin: 50px;
    font-size: var(--dimensione-testo-enorme);
    color: var(--nero);
}
.login-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.login-body button{
    width: auto;
    height: auto;
    border-radius: 20px;
    border: none;
    font-size: var(--dimensione-testo);
    font-weight: bold;
    color: var(--bianco);
    background-color: var(--blu);
    padding: 15px;
}

.login-body button:active{
    background-color: var(--blu-active);
}


.login-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
}

.login-footer p{
    text-decoration: underline;
    color: var(--arancione-scritte);
    font-weight: bold;
}

.login-footer p:hover{
    cursor: pointer;
}