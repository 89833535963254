.container-invita{
    background-color: var(--blu-chiaro);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;

}

.seleziona-tutti{
    text-decoration: underline;
    color: var(--blu);
    font-weight: bold;
    margin-bottom: 20px;
}

.indeterminate{
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;

}

.iscritti{
  background-color: var(--arancione-scritte) !important;
  border-color: var(--arancione-scritte) !important;
}

.form-check-input{
  border: gray solid 2px !important;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label
{
  cursor: default;
  opacity: 1;
}

.form-check-input:disabled
{
  pointer-events: none;
  filter: none;
  opacity: .3;
}

.barra-ricerca{
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 20px;
}

.cerca-utente{
  position: absolute;
  right: 3%;
  top: 10%
}

.oppure-seleziona-alcuni{
  margin-bottom: 20px;
}

.container-tutti-nomi{
  background-color: var(--lavender-web);
  padding: 20px;
  border-radius: 20px;
  margin: 20px;
  min-width: 250px;
}

.container-nomi{
  color: var(--nero);
}

.ruolo-iscritto{
  color: var(--arancione-scritte);
  font-weight: bold;
  margin-left: 5px;
}

.descrizione-sezione{
  font-weight: bold;
  color: var(--arancione-scritte)
}


