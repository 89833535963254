
.formPalestra{
    overflow-x: auto;
    overflow-y: auto;
}

.campo label{
    margin:0;
}

.campo{
    margin: 10px;
    margin-bottom: 20px;
}

.bott{
    display: flex;
    justify-content: center;
}
