.blocco-card{
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    font-size: var(--dimensione-testo);
    border:none;
    padding: 5px;
    background-color: var(--bianco);
    position: relative;
}

.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    gap: 5px;
    max-height: 50px;
}

.contenuto{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    gap: 5px;
}

.icone{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    gap: 5px;
    position: absolute;
    right: 5px;
    top: 5px;   
}

.icona-card{
    width: 35px;
    height: auto;
}

.icona-luogo{
    width: 17px;
    height: auto;
}

.sport{
    font-size: var(--dimensione-testo-grande);
    font-weight: bold;
    text-transform: uppercase;

}
.livello{
    text-transform: uppercase;
    font-weight: bold;
}

.quando{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blu);
    border-radius: 20px;
    color: var(--bianco);
    flex-grow: 1;
    min-width: 70px;
}

.giorno{
    font-size: var(--dimensione-testo-grande);
    font-weight: bold;
}

.ora{
    font-size: var(--dimensione-testo-grande);
    font-weight: bold;
}

.sport{
    text-transform: uppercase;
    font-weight: bold;
}

.luogo{
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.bottoni-azioni{
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 40px;
    max-width: 127px;
    margin-top: 50px;
    flex-grow: 1;
}

.bottoni-azioni-consigliata{
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 40px;
    max-width: 135px;
    margin-top: 50px;
    flex-grow: 1;
}

.bottone-invita{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--blu);
    border-radius: 20px;
    color: var(--bianco);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.bottone-invita:active{
    background-color: var(--blu-active);

}

.bottone-invita p{
    margin: 0;
}

.bottone-modifica{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--blu);
    border-radius: 20px;
    color: var(--bianco);
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}

.bottone-modifica:active{
    background-color: var(--blu-active);

}

.bottone-modifica p{
    margin: 0;
}

.informazioni{
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 150px;
}

.status-icon{
    width: 20px;
    height: auto;
}

.min{
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.ruolo{
    font-weight: bold;
}

.immagine-arbitro{
    width: 25px;
    height: auto;
}

.immagine-fotografo{
    width: 23px;
    height: auto;
}

.icona-luogo{
    width: 20px;
    height: 20px;
}