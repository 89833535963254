:root {
 /*--nero: #000000;
  --bianco: #f5f5f5;
  --arancione: #FF6760;
  --arancione-chiaro: #FFC1BF;
  --blu: #7798F8;
  --blu-chiaro: #DAE6FF;
  --arancione-scritte: steelblue;*/
  --dimensione-testo: 15px;
  --dimensione-testo-piccolo: 13px;
  --dimensione-testo-grande: 18px;
  --dimensione-testo-enorme: 24px;
  /* CSS HEX */
  --arancione-chiaro-tran: rgba(255, 104, 96, 0.615);
  --arancione-chiaro: #ff6760ff;
  --bianco: rgb(255, 255, 255);
  --arancione-scritte:#ff6760ff;
  --giallo: #fbaf00ff;
  --blu-chiaro: #DAE6FF;
  --blu:#7798F8;
  --blu-bu: #007cbeff;
  --giallo-chiaro:#ffd639ff;
  --lavender-web: rgba(236, 242, 255, 0.721);
  --grigio-tran:rgba(199, 204, 216, 0.886) ;
  --blu-active: #244567bd;

  /* CSS HSL 
  --bittersweet: hsla(3, 100%, 69%, 1);
  
  --selective-yellow: hsla(42, 100%, 49%, 1);
  --gold: hsla(48, 100%, 61%, 1);
  --honolulu-blue: hsla(201, 100%, 37%, 1);*/
  }

  @font-face {
    font-family: 'Louis George Cafe';
    src: url('../public/font/Louis-George-Cafe.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Louis George Cafe';
    src: url('../public/font/Louis-George-Cafe-Light.ttf') format('opentype');
    font-weight: lighter;
    font-style: normal;
  }

  @font-face {
    font-family: 'Louis George Cafe';
    src: url('../public/font/Louis-George-Cafe-Light-Italic.ttf') format('opentype');
    font-weight: lighter;
    font-style: italic;
  }

  @font-face {
    font-family: 'Louis George Cafe';
    src: url('../public/font/Louis-George-Cafe-Bold.ttf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
 
  @font-face {
    font-family: 'Louis George Cafe';
    src: url('../public/font/Louis-George-Cafe-Bold-Italic.ttf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }
  
  body {
    font-family: 'Louis George Cafe', sans-serif !important;
    font-size: var(--dimensione-testo);
  }

.App{
    margin:0px;
    height: 100vh;
}




