.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;   
    background-color: var(--blu-chiaro);
    margin:0;
}

.form{
    margin: 20px;
}