.container-partecipa{
    background-color: var(--blu-chiaro);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.header-pagina{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap:107px;
    margin-bottom: 10px;
}

.container-ruoli{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    max-width: 300px;
    gap:30px;
    margin-top: 50px;
}

.container-ruolo{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--lavender-web);
    padding:5px;
    border-radius: 20px;
    font-weight: bold;
    width: 135px;
    position: relative;
    height: 200px;
}

.container-ruolo img{
    height: 133px;
    width: 109px;
}
.info-partecipa{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--lavender-web);
    padding: 10px;
    min-width: 300px;
    margin-top: 35px;
    margin-bottom: 10px;
    border-radius: 20px;
    gap: 20px;
}

.info-el{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}

.descrizione-campo{
    color: var(--arancione-chiaro);
    font-weight: bold;
    text-transform: uppercase;
    margin-top:5px;
}

.el-partecipa{
    font-size: var(--dimensione-testo-grande);
    
}

.ruolo-partecipa{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.post-attività-partecipa{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.descrizione-attività{
    background-color: var(--bianco);
    border-radius: 20px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    min-width: 200px;
    min-height: 80px;
    padding: 10px;
    display: flex;
    justify-content: center;
}
form{
    text-align: center;
}

.avviso-scadenza{
    margin:20px;
    font-size: var(--dimensione-testo-grande);
    background-color: var(--lavender-web);
    color: var(--arancione-chiaro);
    border-radius: 20px;
    border-style: dotted;
    padding: 10px;
}

.indietro{
    border-radius: 20px;    
    border: none;
    color: var(--blu)!important;
    font-size: var(--dimensione-testo)!important;
    padding: 5px;
    margin: 0 !important;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent !important;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    font-weight: bold;
    gap: 5px;
    text-decoration: underline;
}

.bottone{
    width: auto;
    height: auto;
    border-radius: 20px;
    border: none;
    font-size: var(--dimensione-testo);
    font-weight: bold;
    color: var(--bianco);
    background-color: var(--blu);
    padding: 10px;
    margin-top: 50px;
}

.bottone:active{
    background-color: var(--blu-active);
}

.bottone-disattivo{
    width: auto;
    height: auto;
    border-radius: 20px;
    border: none;
    font-size: var(--dimensione-testo);
    font-weight: bold;
    color: var(--bianco);
    padding: 10px;
    margin-top: 50px;
    background-color: lightgray !important;
    border: gray solid 1px;
    color: gray;
  }

.dettagli-ruolo{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.bottone-modale{
    width: auto;
    height: auto;
    border-radius: 20px;
    border: none;
    font-size: var(--dimensione-testo);
    font-weight: bold;
    color: var(--bianco);
    background-color: var(--blu);
    padding: 10px;
}
.bottone-modale:active{
    background-color: var(--blu-active) !important;
}